import { useCallback, useEffect, useState } from 'react';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import { SetQuantity, UpdateCart } from '../../Pages/CartPage/Cart';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { styled } from '../../stitches.config';
import MinusIcon from '../Icons/MinusIcon';
import PlusIcon from '../Icons/PlusIcon';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

type PropTypes = {
  lineItem: VariationModel;
  canUpdateCart: boolean;
  isCheckout: boolean;
};

type PropTypesQuantity = {
  onQuantityBlur: any;
  inputQuantity: string;
  onQuantityFocus: any;
  onQuantityChange: any;
};

function QuantityInput({
  inputQuantity,
  onQuantityFocus,
  onQuantityChange,
}: PropTypesQuantity) {
  return (
    <Quantity
      onFocus={onQuantityFocus}
      onChange={onQuantityChange}
      value={inputQuantity}
      style={{
        width: inputQuantity?.length * 9 + 'px',
      }}
      id={'cartProductQuantity'}
    />
  );
}

function CartItemQuantitySelector({
  lineItem,
  canUpdateCart = false,
  isCheckout = false,
}: PropTypes) {
  const { languageRoute } = useAppSettingsData();
  const { channelId } = useCurrentPage();
  const { 'product/salesQuantity': salesQuantityLabel } = useTranslationData();
  const [timer, setTimer] = useState<any>(null);

  const [quantityError, setQuantityError] = useState(false);

  const [inputQuantity, setInputQuantity] = useState<string>(
    lineItem.quantity?.toString()
  );

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  function handleUpdateCart(quantity: number) {
    UpdateCart(lineItem.articleNumber, quantity, languageRoute, litiumContext);
  }

  const onQuantityMinus = () => {
    setQuantityError(false);
    setInputQuantity(
      (Number(inputQuantity) - lineItem.minimumSalesQuantity)?.toString()
    );
    SetQuantity(
      lineItem.articleNumber,
      Number(Number(inputQuantity) - lineItem.minimumSalesQuantity),
      languageRoute,
      litiumContext
    );
    if (canUpdateCart) {
      handleUpdateCart(
        Number(Number(inputQuantity) - lineItem.minimumSalesQuantity)
      );
    }
  };

  const onQuantityPlus = () => {
    setQuantityError(false);
    setInputQuantity(
      (Number(inputQuantity) + lineItem.minimumSalesQuantity)?.toString()
    );
    SetQuantity(
      lineItem.articleNumber,
      Number(Number(inputQuantity) + lineItem.minimumSalesQuantity),
      languageRoute,
      litiumContext
    );
    if (canUpdateCart) {
      handleUpdateCart(
        Number(Number(inputQuantity) + lineItem.minimumSalesQuantity)
      );
    }
  };

  useEffect(() => {
    if (lineItem.quantity?.toString() !== inputQuantity) {
      setInputQuantity(lineItem.quantity?.toString());
    }
  }, [lineItem]);

  const onQuantityFocus = (e: any) => {
    e.target.select();
    setQuantityError(false);
  };

  const onQuantityChange = (event: any) => {
    event.target.style.width = event?.target?.value?.length * 6 + 'px';

    clearTimeout(timer);
    setInputQuantity(event.target.value);
    const newTimer = setTimeout(() => {
      if (Number(event.target.value) > lineItem.minimumSalesQuantity) {
        setInputQuantity(
          (
            Math.ceil(
              Number(event.target.value) / lineItem.minimumSalesQuantity
            ) * lineItem.minimumSalesQuantity
          ).toString()
        );
        SetQuantity(
          lineItem.articleNumber,
          Number(
            Math.ceil(
              Number(event.target.value) / lineItem.minimumSalesQuantity
            ) * lineItem.minimumSalesQuantity
          ),
          languageRoute,
          litiumContext
        );
      } else {
        setInputQuantity(lineItem.minimumSalesQuantity.toString());
        SetQuantity(
          lineItem.articleNumber,
          Number(lineItem.minimumSalesQuantity),
          languageRoute,
          litiumContext
        );
      }
    }, 500);
    setTimer(newTimer);
  };

  return (
    <QuantityWrapper>
      <>
        <MinusButton
          disabled={inputQuantity === lineItem.minimumSalesQuantity?.toString()}
          onClick={() => onQuantityMinus()}
        >
          <StyledMinusIcon size={'quantity'} noMargin />
        </MinusButton>
        <SalesUnitWrapper>
          {isCheckout ? (
            <QuantityInput
              inputQuantity={inputQuantity}
              onQuantityFocus={onQuantityFocus}
              onQuantityChange={onQuantityChange}
            />
          ) : (
            <QuantityInput
              inputQuantity={inputQuantity}
              onQuantityFocus={onQuantityFocus}
              onQuantityChange={onQuantityChange}
            />
          )}
          {lineItem?.priceModel?.price?.unitOfMeasurementTitle && (
            <QuantityLabel htmlFor={'cartProductQuantity'}>
              {lineItem?.priceModel?.price?.unitOfMeasurementTitle}
            </QuantityLabel>
          )}
        </SalesUnitWrapper>
        <button onClick={() => onQuantityPlus()}>
          <StyledPlusIcon size={'quantity'} noMargin />
        </button>

        {quantityError && (
          <QuantityError className={isCheckout ? 'b2b' : ''}>
            {salesQuantityLabel}
          </QuantityError>
        )}
      </>
    </QuantityWrapper>
  );
}

export default CartItemQuantitySelector;

const QuantityWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  p: 2,
  border: '1px solid #DDDAD8',
  maxH: 8,
  maxW: 29,
  br: 1,
  w: '100%',
});

const QuantityLabel = styled('label', {});

const SalesUnitWrapper = styled('div', {
  w: '100%',
  gap: '3px',
  background: '$white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '@mediaMinLarge': {
    minW: 20,
  },
});

const Quantity = styled('input', {
  display: 'flex',
  background: 'transparent',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  width: '21px',
  '&:active': {
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
});

const QuantityError = styled('span', {
  position: 'absolute',
  color: '#9a2626',
  bottom: '30px',
  textAlign: 'center',
  left: '2px',
  lineHeight: '10px',
  '&.b2b': {
    width: 'max-content',
  },
});

const MinusButton = styled('button', {
  '&:disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const StyledPlusIcon = styled(PlusIcon, {
  wh: 4.5,
});

const StyledMinusIcon = styled(MinusIcon, {
  wh: 4.5,
});
