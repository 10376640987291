import { useRef, useState, useEffect } from 'react';
import CartProductList from '../ProductList/CartProductList';
import { GetCart } from '../../Pages/CartPage/Cart';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import Overlay from '../../Atoms/Overlay/Overlay';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import { CloseIcon } from '../../Atoms/Icons';

import {
  miniCartSlideIn,
  miniCartSlideInMobile,
} from '../../Theme/Settings/keyframes';
import EmptyCartContainer from '../EmptyCartContainer/EmptyCartContainer';

export type PropType = {
  isOpen: boolean;
  closeMiniCart: () => void;
  openMiniCart: () => void;
};

function MiniCart({ isOpen, closeMiniCart, openMiniCart }: PropType) {
  const { cart, isLoading } = GetCart();
  const cartRef = useRef<HTMLDivElement>(null);
  const [renderCart, setRenderCart] = useState<boolean>(false);
  const [topPadding, setTopPadding] = useState<number>(0);
  const [showClearCartModal, toggleClearCartModal] = useState(false);
  const {
    staticPages: { checkoutPage },
  } = useAppSettingsData();

  const isEmpty = cart.numberOfItems === 0;
  const {
    'common/goToCheckout': goToCheckout,
    'minicart/items': itemsLabel,
    'cartPage/cartHeader': miniCartLabel,
    'common/loading': loadingLabel,
    'common/freightInformationLabel': freightInformationLabel,
    'checkoutPage/subtotal': subTotalLabel,
    'checkoutPage/totalsum': totalSumLabel,
    'checkoutPage/vatTotal': vatLabel,
  } = useTranslationData();

  useOutsideClick(cartRef, () => {
    if (!showClearCartModal) {
      isOpen && closeMiniCart();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setRenderCart(true);
    }
    let headerElm = document.getElementById('header');
    headerElm && setTopPadding(headerElm.offsetHeight);
  }, [isOpen]);

  const headerHeightCalcStyle = {
    maxH: `calc(100vh - ${topPadding}px)`,
  };

  return !isEmpty ? (
    <>
      {isOpen && <Overlay />}
      {isOpen && (
        <ModalRoot css={headerHeightCalcStyle}>
          <Root>
            <ModalContent>
              <VerticalLimiter>
                <Wrapper css={headerHeightCalcStyle}>
                  <MiniCartContentContainer
                    ref={cartRef}
                    key={cart.totalPrice}
                    onTransitionEnd={() => {
                      !isOpen && setRenderCart(false);
                    }}
                  >
                    {renderCart && (
                      <>
                        {isLoading && <>{loadingLabel}</>}
                        <MiniCartWrapper css={headerHeightCalcStyle}>
                          <Top>
                            <TextWrapper>
                              <CartLabel>{miniCartLabel} &nbsp;</CartLabel>
                              {cart.numberOfItems} {itemsLabel}
                            </TextWrapper>
                            <button onClick={closeMiniCart}>
                              <CloseIcon size="xs" />
                            </button>
                          </Top>
                          <CartProductList
                            products={cart.lineItems}
                            isLoading={isLoading}
                            isMiniCart
                            allowEdit
                            openMiniCart={openMiniCart}
                            showClearCartModal={showClearCartModal}
                            toggleClearCartModal={toggleClearCartModal}
                          />
                          <Bottom>
                            <SubTotal>
                              <SubTotalText>{subTotalLabel}</SubTotalText>
                              <SubTotalText>
                                {cart.totalPriceForItems}
                              </SubTotalText>
                            </SubTotal>
                            <SubTotal>
                              <SubTotalText>{vatLabel}</SubTotalText>
                              <SubTotalText>{cart.totalVATPrice}</SubTotalText>
                            </SubTotal>
                            <SubTotal>
                              <SubTotalText>{totalSumLabel}</SubTotalText>
                              <SubTotalText>{cart.totalPrice}</SubTotalText>
                            </SubTotal>
                            <Flex>
                              <ShippingText>
                                {freightInformationLabel}
                              </ShippingText>
                            </Flex>
                            <ButtonWrapper>
                              <StyledCtaButton
                                onClick={closeMiniCart}
                                href={checkoutPage}
                                type={'secondary'}
                                size={'s'}
                                uppercase
                              >
                                {goToCheckout}
                              </StyledCtaButton>
                            </ButtonWrapper>
                          </Bottom>
                        </MiniCartWrapper>
                      </>
                    )}
                  </MiniCartContentContainer>
                </Wrapper>
              </VerticalLimiter>
            </ModalContent>
          </Root>
        </ModalRoot>
      )}
    </>
  ) : (
    <>
      {isOpen && <Overlay />}
      {isOpen && (
        <ModalRoot css={headerHeightCalcStyle}>
          <RootEmpty>
            <ModalContent>
              <VerticalLimiter>
                <Wrapper css={headerHeightCalcStyle}>
                  <EmptyMiniCartContentContainer
                    ref={cartRef}
                    key={cart.totalPrice}
                    onTransitionEnd={() => {
                      !isOpen && setRenderCart(false);
                    }}
                  >
                    <MiniCartWrapper css={headerHeightCalcStyle}>
                      <TopEmpty>
                        <TextWrapper>
                          <CartLabel>{miniCartLabel} &nbsp;</CartLabel>
                          {cart.numberOfItems} {itemsLabel}
                        </TextWrapper>
                        <button onClick={closeMiniCart}>
                          <CloseIcon size="xs" />
                        </button>
                      </TopEmpty>
                      <EmptyCartContainer />
                    </MiniCartWrapper>
                  </EmptyMiniCartContentContainer>
                </Wrapper>
              </VerticalLimiter>
            </ModalContent>
          </RootEmpty>
        </ModalRoot>
      )}
    </>
  );
}

const ModalRoot = styled('div', {
  position: 'fixed',
  t: 'unset',
  l: 0,
  r: 0,
  b: 0,
  zIndex: '$CartMobile',
  display: 'flex',
});

const Top = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  py: 4,
  mx: 4,
  borderBottom: '1px solid $grey100',
  fs: 7,
});

const TextWrapper = styled('div', {
  display: 'flex',
  fontSize: '14px',
  fontWeight: '$fw400',
  lineHeight: '24px',
  letterSpacing: '1px',
  alignItems: 'flex-end',
});

const CartLabel = styled('span', {
  fontWeight: '$fw600',
  mt: 0.25,
});

const StyledCtaButton = styled(CtaButton, {
  mt: 6,
});

const ButtonWrapper = styled('div', {
  mx: 'auto',
  w: 'fit-content',
});

const Root = styled('div', {
  backgroundColor: 'transparent',
  wh: '100%',
  position: 'relative',
  animation: `${miniCartSlideInMobile} .4s ease-in-out`,
  transition: 'right .4s ease-in-out',
  '@mediaMinLarge': {
    animation: `${miniCartSlideIn} .4s ease-in-out`,
    transition: 'top .4s ease-in-out',
  },
});

const ModalContent = styled('div', {
  backgroundColor: 'transparent',
  w: '100%',
  maxW: '1200px',
  mx: 'auto',
  h: '100vh',
});

const Wrapper = styled('div', {
  ml: 'auto',
  maxW: 80,
  '@mediaMaxVerySmall': {
    h: '100%',
  },
});

const VerticalLimiter = styled('div', {
  w: '100%',
  position: 'relative',
  '@mediaMaxVerySmall': {
    h: '100%',
  },
});

const MiniCartWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  h: '100%',
});

const Bottom = styled('div', {
  pt: 3,
  pb: 6,
  pl: 6,
  pr: 6,
  fs: 6,
  lineHeight: '20px',
  w: '100%',
  backgroundColor: '$backgroundPrimary',
  boxShadow:
    '0 2px 32px 2px rgba(59, 72, 80, 0.05), 0 0 4px 0 rgba(72, 72, 72, 0.15)',
  '@mediaMinSmall': {
    boxShadow: '0 2px 6px 0 rgba(72, 72, 72, 0.15)',
  },
});

const Flex = styled('div', {
  display: 'flex',
  mt: 1,
});

const ShippingText = styled('span', {
  color: '$textPrimary',
});

const SubTotal = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1,
});

const SubTotalText = styled('div', {
  fontWeight: '$fw600',
  color: '$textPrimary',
});

const MiniCartContentContainer = styled('div', {
  transition: 'all $300',
  right: 0,
  '@mediaMaxVerySmall': {
    h: '100%',
  },
  '@mediaMinVerySmall': {
    w: 80,
  },
  backgroundColor: '$backgroundPrimary',
  '::-webkit-scrollbar': {
    w: 1,
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '8px',
    backgroundColor: '$white',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#D9D9D9',
  },
});

const EmptyMiniCartContentContainer = styled('div', {
  right: 0,
  '@mediaMaxVerySmall': {
    h: '100%',
  },
  '@mediaMinVerySmall': {
    w: 80,
  },
  backgroundColor: '$backgroundPrimary',
  '::-webkit-scrollbar': {
    w: 1,
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '8px',
    backgroundColor: '$white',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: '#D9D9D9',
  },
});

const TopEmpty = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  py: 4,
  mx: 4,
  fs: 7,
});

const Empty = styled('div', {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  py: 4,
  mx: 4,
});

const BottomEmpty = styled('div', {
  pt: 3,
  pb: 6,
  pl: 6,
  pr: 6,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '1px',
  w: '100%',
  backgroundColor: '$backgroundPrimary',
  textAlign: 'center',
  h5: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '26px',
    letterSpacing: '1px',
  },
});

const RootEmpty = styled('div', {
  backgroundColor: 'transparent',
  wh: '100%',
  position: 'relative',
});

export default MiniCart;
