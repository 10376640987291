import { styled } from '../../stitches.config';
import Modal from '../Modal/Modal';
import { ClearCart } from '../../Pages/CartPage/Cart';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';

type PropType = {
  toggle: () => void;
  overlay: boolean;
  clearCart: boolean;
};

function ClearCartModal({ overlay, toggle, clearCart }: PropType) {
  const {
    'common/clearCartTitle': clearCartTitle,
    'common/clearCartDescription': clearCartDescription,
    'common/confirm': confirmLabel,
    'common/cancel': cancelLabel,
  } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const { channelId } = useCurrentPage<PageModelBase>();
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  return (
    <Modal
      overlay={true}
      closeLabel={''}
      toggle={() => toggle()}
      clearCart={true}
      outSideClick={false}
    >
      <h3>{clearCartTitle}</h3>
      <div>{clearCartDescription}</div>
      <ButtonClearCartWrapper>
        <StyledCtaButton
          onClick={() => toggle()}
          type={'cancel'}
          clearcart={true}
          uppercase={true}
          size={'clearCart'}
        >
          {cancelLabel}
        </StyledCtaButton>
        <StyledCtaButton
          onClick={() => {
            toggle();

            // Use setTimeout to clear cart otherwise state is not updated.
            setTimeout(() => {
              ClearCart(languageRoute, litiumContext);
            }, 100);
          }}
          type={'secondary'}
          clearcart={true}
          uppercase={true}
          size={'clearCart'}
        >
          {confirmLabel}
        </StyledCtaButton>
      </ButtonClearCartWrapper>
    </Modal>
  );
}

const ButtonClearCartWrapper = styled('div', {
  display: 'flex',
  gap: '10px',
  w: 'fit-content',
  mt: '12px',
});

const StyledCtaButton = styled(CtaButton, {
  mt: 6,
});

export default ClearCartModal;
